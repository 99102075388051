import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import Checkbox from '@material-ui/core/Checkbox';
import '../App.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';*/
import Config from '../assets/config';
import Cities from '../assets/cities';
import Checkbox from '@material-ui/core/Checkbox';
import { postFileDownload, postFetch } from '../http';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
//import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import { Switch, Route } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from "react-router-dom";
import moment from "moment";
import NumberFormat from 'react-number-format';
import _ from 'lodash';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        margin: '30px 10px',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%)',
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 1
        }
    },
    header: {
        backgroundColor: "#1a75c9",
        color: "#fff",
        marginBottom: 10,
        padding: 10,
        fontWeight: 900,
        fontSize: 15
    },
    filters: {
        "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
            color: "#000",
            fontSize: 17
        },
        margin: '20px 0',
        padding: '0 10px'
    },
    textfield: {
        padding: '10px 10px 10px 0',
        position: 'relative'
    },
    textLabel: {
        position: 'relative',
        bottom: '8px',
        fontSize: '14px',
        fontWeight: '500'
    },
    datepicker: {
        position: 'relative',
        bottom: 3,
        padding: '0 10px 0 0'
    },
    formControl: {
        width: "100%",
        marginTop: 1
    },
    addressField: {
        width: 400,
        marginTop: 1
    },
    fabIcon: {
        padding: '10px',
        marginTop: '34px'
    },
    radioLabel: {
        position: 'relative',
        top: 13,
        marginRight: 20
    }
}));

const Subheader = ({ children }) => {
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="##########"
            mask="_"
        />
    );
}

function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            prefix="$"
        />
    );
}

export default function MileageExp(props) {
    const classes = useStyles();
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [summaryData, setSummaryData] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [onInit, setOnInit] = React.useState(true);
    const [mealsDetails, setMealsDetails] = React.useState({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, totalExpBeforeTips: null, totalTips: null, allowedTips: null, totalExpPaid: null, maxAllowedAmount: null, rembAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medApprovalStatus: null, medRevReason: null, delSw: 'N', id: null, internalOrder: null, costCenter: null, outOfState: 'N', outsideWorkHours: 'N' });
    const [error, setError] = React.useState(false);
    const [meals, setMeals] = React.useState([{ mealType: "Breakfast", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
    { mealType: "Lunch", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
    { mealType: "Dinner", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }]);
    const [medicaid, setMedicaid] = React.useState('N');
    const [medicaidSw, setMedicaidSw] = React.useState(false);
    const [currentMedicaid, setCurrentMedicaid] = React.useState({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null });
    const [documents, setDocuments] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [states, setStates] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [oldMealsDetails, setOldMealsDetails] = React.useState([]);
    const [oldMeals, setOldMeals] = React.useState([]);
    const [defaultRembAmt, setDefaultRembAmt] = React.useState(0);
    const [defaultDhsCardAmount, setDefaultDhsCardAmount] = React.useState(0);
    const [stateHoliday, setStateHoliday] = React.useState([]);
    const [initialMealDetails, setInitialMealDetails] = React.useState([]);
    const [showWarning, setShowWarning] = React.useState(false);  // New state for controlling visibility
    const [warningMessage, setWarningMessage] =React.useState(''); // Stores the warning message
    const [returnRevisionSw, setReturnRevisionSw ]  = React.useState(false);
    const changeMedicaidDetails = (key, value) => {
        let newDetails = { ...currentMedicaid };
        // if(newDetails[key] !== value)
        // {
        //     setIsRREdited(true);
        // }
        if (key === 'medDob' && (value !== null) && (value > new Date(new Date().toDateString()))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Date of Birth must be a past or current date.' });
        }
        newDetails[key] = value;
        setCurrentMedicaid(newDetails);
    }

    const woTips = (row, column, index) => {
        return (
            <TextField
                id={`expnoTips ${row.id}`}
                variant="outlined"
                size="small"
                fullWidth
                value={(row.expWoTip === null || row.expWoTip === '') ? '' : parseFloat(row.expWoTip).toFixed(2)}
                onChange={(e) => changeMealsTypeDetails(index, 'expWoTip', e.target.value)}
                className={readOnly ? "disabled" : null}
                disabled={readOnly}
                onBlur={() => changeReimAmount()}
                InputProps={{
                    inputComponent: AmountFormatCustom,
                }}
            />
        )
    }

    const tips = (row, column, index) => {
        return (
            <TextField
                id={`tips ${row.id}`}
                variant="outlined"
                size="small"
                fullWidth
                className={readOnly ? "disabled" : null}
                disabled={readOnly}
                value={(row.tips === null || row.tips === '') ? '' : parseFloat(row.tips).toFixed(2)}
                onChange={(e) => changeMealsTypeDetails(index, 'tips', e.target.value)}
                onBlur={(e) => { changeReimAmount(); changeMealTipValidation(index, 'tips') }}
                InputProps={{
                    inputComponent: AmountFormatCustom,
                }}
            />
        )
    }

    const changeMealTipValidation = (index, key) => {
        let newDetails = [...meals];
        if (key === 'tips' && (newDetails[index]["expWoTip"] !== null && newDetails[index]["expWoTip"] !== '')) {
            if (parseFloat(newDetails[index]["tips"]) > (parseFloat(0.15) * parseFloat(newDetails[index]["expWoTip"]))) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Tips cannot exceed 15% of the expense.' });
                }, 1000);
            }
        }
    }

    const changeMealsTypeDetails = (index, key, value) => {
        let newDetails = [...meals];
        //if(newDetails[index][key] !== value)
        // {
        //     setIsRREdited(true);
        // }
        if (key === 'tips' && (newDetails[index]["expWoTip"] === null || newDetails[index]["expWoTip"] === '' || parseFloat(newDetails[index]["expWoTip"]) <= parseFloat(0))) {
            value = null;
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Tips without expense cannot be reimbursed." });
            }, 1000);
        }
        if (key === 'expWoTip' && (value === null || value === '' || parseFloat(value) <= parseFloat(0))) {
            newDetails[index]["tips"] = null;
        }
        newDetails[index][key] = value;
        if (newDetails[index]["medSw"] === 'N') {
            newDetails[index]["medStatus"] = 'No';
            setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: index, internalOrder: null, costCenter: null });
            setMedicaid('N');
        }
        if (newDetails[index]["medSw"] === 'Y') {
            setCurrentMedicaid({ medFirstname: newDetails[index]["medFirstname"], medLastname: newDetails[index]["medLastname"], medDob: newDetails[index]["medDob"], medId: newDetails[index]["medId"], index: index, internalOrder: newDetails[index]["internalOrder"], costCenter: newDetails[index]["costCenter"] });
            setMedicaid('Y');
        }

        let updatedDetails = { ...mealsDetails };
        setMeals(newDetails);
        expenseCal(updatedDetails);
    }


    /*const Medicaid = (row,column,index)=>{
        return (
        <FormControl component="fieldset">
                        <RadioGroup row aria-label="med-sw" name="med-sw"  value={row.medSw} onChange={(e)=> changeMealsTypeDetails(index,'medSw',e.target.value)}>
                            <div className={classes.radioButton}>
                                <FormControlLabel value="Y" control={<Radio  disabled = {readOnly} color="primary" />} label="Yes" />
                                <FormControlLabel value="N" control={<Radio  disabled = {readOnly} color="primary" />} label="No" />
                            </div>
                        </RadioGroup>
        </FormControl>)
    }*/

    const documentMedicaid = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeDocumentDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled={readOnly} color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled={readOnly} color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const changeDocumentDetails = (index, key, value) => {
        let updatedDocs = [...documents].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[index][key] = value;
        setDocuments(updatedDocs);
    }

    const documentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon className={readOnly ? "disabled" : null} disabled={readOnly} onClick={() => readOnly ? null : changeDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }


    /*const actionButton = (row,column,index)=>{
        return (<div style={{cursor:'pointer'}}><EditRoundedIcon onClick={()=>viewMedicaid(row,index)} style={{fontSize:20}}/></div>);
    }*/


    React.useEffect(() => {
        const loadMealsDetails = async () => {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            setOnInit(false);
            history.push("/tr1");
            const request = {
                rembId: parseInt(localStorage.getItem("requestId"))
            }
            await postFetch("/meals/getByRembId", request).then((data) => {
                if (!data.message) {
                    let summary = [];
                    let totalAmount = 0;
                    let existingDates = localStorage.getItem("dates").split(",");
                    existingDates = existingDates.filter((date) => date.indexOf("me") === -1);
                    data.forEach(exp => {
                        exp.sumexpenseDate = convertDateFromServer(exp.expenseDate);
                        existingDates = existingDates + "," + exp.expenseDate + "me";
                        exp.sumexpenseReason = props.staticData.reference.ReasonForExpense.value.find((config) => config.value === exp.expReason).name;
                        exp.sumamount = "$" + exp.rembAmount;
                        let status = 'IP';
                        let medicaidCount = [...exp.mealsDetails].filter((meal) => (meal.medSw === 'Y'));
                        if (medicaidCount.length > 0 && medicaidCount.length !== exp.mealsDetails.length) {
                            status = (medicaidCount[0].medApprovalStatus !== null) ? medicaidCount[0].medApprovalStatus : "SB";
                        }
                        else {
                            status = exp.status;
                        }
                        exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === status).name;
                        if (exp.delSw !== 'Y') {
                            summary.push(exp);
                            totalAmount = parseFloat(totalAmount) + parseFloat(exp.rembAmount);
                        }
                    });
                    localStorage.setItem("dates", existingDates);
                    if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && (props.reimbursement.travelSupervisor.toString() === props.userInfo.id.toString())){
                        setReturnRevisionSw(true);
                      }
                    setSummaryData(summary);
                    setInitialMealDetails(JSON.parse(JSON.stringify(summary)))
                    setTotalAmount(parseFloat(totalAmount.toFixed(2)).toFixed(2));
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false });
                    }, 1000);
                    if (localStorage.getItem("readOnly") !== null) {
                        setReadOnly(true);
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        if (onInit && (localStorage.getItem("requestId") !== undefined) && (localStorage.getItem("requestId") !== null)) {
            loadMealsDetails();
            getStateHolidays();
        }
    }, [props, onInit, summaryData, history]);

    function convertDateFromServer(value) {
        if ((value !== null) && !(value instanceof Date)) {
            let serverDateArray = value.split("-");
            if (serverDateArray.length > 1) {
                return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
            }
            else {
                return serverDateArray[0];
            }
        }
        else if (value instanceof Date) {
            return value;
        }
        else {
            return null;
        }

    }
    const saveMeals = async () => {
        if (document.getElementsByClassName("Mui-error").length > 0) {
            setError(true);
        }
         else if ([...documents].filter((doc)=> doc.delSw !== 'Y').length === 0){
            props.loader({loader:false,notification:true,message:"error",validationMessage:"At least one document is required to submit expense." });
         }
        else if (props.reimbursement.expenseStartDate === null || props.reimbursement.expenseEndDate === null) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Total Reimbursement Expense Start Date or Expense End Date is missing" });
        }
        else if (mealsDetails.status === 'SB' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        }
        else {
            if (localStorage.getItem("requestId") === null) {
                props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
                let request = { ...props.reimbursement };
                request.expenseStartDate = request.expenseStartDate === null ? null : new Date(request.expenseStartDate);
                request.expenseEndDate = request.expenseEndDate === null ? null : new Date(request.expenseEndDate);
                await postFetch("/reimbursement/save", request).then((data) => {
                    if (!data.message) {
                        setTimeout(() => {
                            localStorage.setItem("requestId", data.id);
                            localStorage.setItem("recordVersion", data.recordVersion);
                            saveExpense(data.id);
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                        }, 1000);
                    }
                }).catch((e) => {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                });
            }
            else {
                saveExpense(localStorage.getItem("requestId"));
            }
        }
    }
    const saveExpense = async (reimId) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...mealsDetails };

        request.rembId = reimId;
        request.expenseDate = new Date(request.expenseDate);
        request.dhsCardAmount = request.dhsCardAmount !== null ? parseFloat(request.dhsCardAmount) : null;
        request.totalExpBeforeTips = request.totalExpBeforeTips !== null ? parseFloat(request.totalExpBeforeTips) : null;
        request.totalTips = request.totalTips !== null ? parseFloat(request.totalTips) : null;
        request.allowedTips = request.allowedTips !== null ? parseFloat(request.allowedTips) : null;
        request.totalExpPaid = request.totalExpPaid !== null ? parseFloat(request.totalExpPaid) : null;
        request.maxAllowedAmount = request.maxAllowedAmount !== null ? parseFloat(request.maxAllowedAmount) : null;
        request.rembAmount = request.rembAmount !== null ? parseFloat(request.rembAmount) : null;
        //request.ledgerCode = props.staticData.dropdown.GeneralLedgerCodes.value.find((config) => config.value === request.expReason).name;
        request.documents = [...documents];
        request.mealsDetails = [...meals].filter((meal) => ((meal.expWoTip !== null) && (meal.expWoTip !== '')));

        if (props.reimbursement.status === 'RR') {
            let oldMealsDtl = { ...oldMealsDetails };
            oldMealsDtl.expenseDate = new Date(oldMealsDtl.expenseDate);
            oldMealsDtl.rembId = reimId;
            oldMealsDtl.mealsDetails = [...oldMeals].filter((meal) => ((meal.expWoTip !== null) && (meal.expWoTip !== '')));
            if (!_.isEqual(oldMealsDtl, request)) {
                request.status = 'IP';
            }
        }
        //request.status = (props.reimbursement.status === 'RR' && isEdited) ? 'IP' : request.status;       
        if (request.medApprovalStatus !== null) {
            request.mealsDetails.forEach((meal) => {
                meal.medApprovalStatus = request.medApprovalStatus;
                meal.medRevReason = request.medRevReason;
            });
        }
        if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && request.status === 'IP' && props.reimbursement.assignedTo === props.userInfo.id)
        {
            request.status = 'SB'
        }
        await postFetch("/meals/save", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Meals Expense saved successfully." });
                }, 1500);
                setOnInit(true);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const upload = async (target) => {
        if (target.files.length === 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "No File Selected" });
        }
        else if (target.files[0].type.length === 0) {
            target.value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Upload Failed. Please upload a document with a valid file extension such as .doc,.docx, .pdf, .txt, .jpeg etc." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            var fileByteArray = [];
            const fileReader = new FileReader();
            fileReader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                }
            }
            fileReader.readAsArrayBuffer(target.files[0]);
            setTimeout(() => {
                const request = {};
                request.DocumentName = target.files[0].name;
                request.DocumentTitle = target.files[0].name + "_" + props.userInfo.id;
                request.DocumentID = null;
                request.DatabaseName = Config.edocutusDatabase;
                request.DocumentTypeID = Config.edocutusDocumentTypeId;
                request.DocumentData = fileByteArray;
                postFetch("/document", request).then((data) => {
                    if (data.documentID !==null &&  !data.message) {
                        let updatedDocs = [...documents];
                        updatedDocs.push({ id: null, fileType: target.files[0].type, fileName: "Meals Expense - " + target.files[0].name, size: (target.files[0].size / 1048576).toFixed(2) + " MB", edocId: data.DocumentID, medSw: 'N', mealsId: null, delSw: 'N' });
                        setDocuments(updatedDocs);
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "success", validationMessage: "Document uploaded successfully" });
                        }, 1000);
                    }
                    else {
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed" });
                        }, 1000);
                    }
                }).catch(() => {
                    target.value = null;
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed." });
                    }, 1000);
                });
            }, 1000);
        }
    }

    const viewDocument = (row, index) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFileDownload("/document?DocumentID=" + row.edocId + "&DatabaseName=" + Config.edocutusDatabase + "&DocumentTypeID=" + Config.edocutusDocumentTypeId).then((data) => {
            if (!data.message) {
                var fileUrl = "data:" + row.fileType + ";base64," + data;
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        var link = window.URL.createObjectURL(blob, { type: row.fileType });
                        window.open(link);
                    });
                setTimeout(() => {
                    props.loader({ loader: false, notification: false });
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const summaryActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewExpense(row, index)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : deleteExpense(row)} style={{ fontSize: 20 }} /></div>);
    }

    const viewExpense = (row, index) => {
        let details = JSON.parse(JSON.stringify(initialMealDetails[index]));
        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
        if (details.mealsDetails.length > 0) {
            details.medApprovalStatus = details.mealsDetails[0].medApprovalStatus;
            details.medRevReason = details.mealsDetails[0].medRevReason;
        }
        setMealsDetails({ ...details });
        getStates();
        getCities(details.state);
        setDocuments([...details.documents]);
        details.mealsDetails.forEach((meal) => {
            meal.medDob = new Date(convertDateFromServer(meal.medDob));
        })
        let bf = [...details.mealsDetails].filter((meal) => meal.mealType === 'Breakfast');
        let ln = [...details.mealsDetails].filter((meal) => meal.mealType === 'Lunch');
        let dn = [...details.mealsDetails].filter((meal) => meal.mealType === 'Dinner');
        if (bf.length === 0) {
            bf = { mealType: "Breakfast", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }
            details.mealsDetails.push(bf);
        } else {
            bf = bf[0];
        }
        if (ln.length === 0) {
            ln = { mealType: "Lunch", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }
            details.mealsDetails.push(ln);
        } else {
            ln = ln[0];
        }
        if (dn.length === 0) {
            dn = { mealType: "Dinner", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }
            details.mealsDetails.push(dn);
        } else {
            dn = dn[0];
        }
        const mealDetails = [bf, ln, dn];
        setMeals(mealDetails);
        setOldMeals(details.mealsDetails);
        let medicaidCount = details.mealsDetails.filter((trip) => trip.medSw === 'Y');
        setMedicaidSw(((medicaidCount.length > 0) && (medicaidCount.length !== details.mealsDetails.length)) ? true : false);
        setOldMealsDetails({ ...details });
        setDefaultRembAmt(details.rembAmount);
        setDefaultDhsCardAmount(details.dhsCardAmount);
        history.push("/tr1/mealsexp");
    }

    const reset = async () => {
        setMedicaid('N');
        let details = { ...mealsDetails };
        if (details.id === null) {
            setMealsDetails({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, totalExpBeforeTips: null, totalTips: null, allowedTips: null, totalExpPaid: null, maxAllowedAmount: null, rembAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medApprovalStatus: null, medRevReason: null, delSw: 'N', id: null, internalOrder: null, costCenter: null,outOfState: 'N', outsideWorkHours: 'N'});
            setError(false);
            setMeals([{ mealType: "Breakfast", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
            { mealType: "Lunch", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
            { mealType: "Dinner", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }]);
            setMedicaid('N');
            setMedicaidSw(false);
            setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null });
            setDocuments([]);
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...details };
            await postFetch("/meals/findById", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false, message: "success" });
                        let details = { ...data };
                        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
                        if (details.mealsDetails.length > 0) {
                            details.medApprovalStatus = details.mealsDetails[0].medApprovalStatus;
                            details.medRevReason = details.mealsDetails[0].medRevReason;
                        }
                        setMealsDetails({ ...details });
                        setDocuments([...details.documents]);
                        setMeals([...details.mealsDetails]);
                        let medicaidCount = details.mealsDetails.filter((trip) => trip.medSw === 'Y');
                        setMedicaidSw(((medicaidCount.length > 0) && (medicaidCount.length !== details.mealsDetails.length)) ? true : false);
                        forceUpdate();
                    }, 1000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }
    const deleteExpense = async (row) => {
        let details = { ...row };
        details.delSw = 'Y';
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...details };
        await postFetch("/meals/save", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Meals Expense saved successfully." });
                    setOnInit(true);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    function viewMedicaid(value, index) {
        setCurrentMedicaid({ medFirstname: value.medFirstname, medLastname: value.medLastname, medDob: value.medDob, medId: value.medId, index: index, internalOrder: value.internalOrder, costCenter: value.costCenter });
        value.medSw === 'Y' ? setMedicaid('Y') : setMedicaid('N');
    }
    const saveMeal = async (index) => {

        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        const request = {
            mediciadId: currentMedicaid.medId,
            firstName: currentMedicaid.medFirstname,
            lastName: currentMedicaid.medLastname,
            dateOfBirth: (currentMedicaid.medDob !== null) ? moment(currentMedicaid.medDob).format("YYYY-MM-DD") : null,
            inquiryBeginDate: (mealsDetails.expenseDate !== null) ? moment(mealsDetails.expenseDate).format("YYYY-MM-DD") : null,
            inquiryEndDt: (mealsDetails.expenseDate !== null) ? moment(mealsDetails.expenseDate).format("YYYY-MM-DD") : null
        }

        await postFetch("/aries/verifyMedicaidStatus2", request).then((data) => {
            if (!data.message) {
                if (data.MessageHeader.Outbound.StatusMessage === 'Matching individual found') {
                    let newAddresses = [...meals];
                    newAddresses[index]["medFirstname"] = currentMedicaid.medFirstname;
                    newAddresses[index]["medLastname"] = currentMedicaid.medLastname;
                    newAddresses[index]["medDob"] = currentMedicaid.medDob;
                    newAddresses[index]["medId"] = currentMedicaid.medId;
                    newAddresses[index]["internalOrder"] = currentMedicaid.internalOrder;
                    newAddresses[index]["costCenter"] = currentMedicaid.costCenter;
                    newAddresses[index]["medStatus"] = "Yes";
                    setMeals(newAddresses);
                    setMedicaid('N');
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "success", validationMessage: "Medicaid Verified Successfully" });
                    }, 1000);
                }
                else {
                    let message = 'Unable to verify Medicaid status: Invalid ';
                    data.ArMedicaidStatusInquiryResponse.MedicaidStatusCodes.StatusCode.forEach((code) => {
                        message += props.staticData.reference.MedicaidStatusCodes.value.find((config) => config.value.toString() === code.toString()).name + ', ';
                    });
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: message });
                    }, 1000);
                }
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const changeReimAmount = () => {

        let newDetails = { ...mealsDetails };
        let expWoTips = parseFloat(0);
        let tips = parseFloat(0);
        let reimbAmount = parseFloat(0);
        let newtips = parseFloat(0);
        meals.forEach((meal) => {
            expWoTips = parseFloat(expWoTips) + ((meal.expWoTip !== null && meal.expWoTip !== '') ? parseFloat(meal.expWoTip) : 0);
            tips = parseFloat(tips) + ((meal.tips !== null && meal.tips !== '') ? parseFloat(meal.tips) : 0);
            if ((meal.tips !== null && meal.tips !== '')) {
                if (parseFloat(meal.tips) > (parseFloat(0.15) * parseFloat(meal.expWoTip))) {
                    newtips = newtips + (parseFloat(0.15) * parseFloat(meal.expWoTip));
                } else {
                    newtips = newtips + parseFloat(meal.tips);
                }
            }

        });

        newDetails.totalExpBeforeTips = parseFloat(expWoTips).toFixed(2);
        newDetails.totalTips = parseFloat(tips).toFixed(2);
        newDetails.totalExpPaid = parseFloat(expWoTips + tips).toFixed(2);
        newDetails.allowedTips = parseFloat(0.15) * parseFloat(newDetails.totalExpBeforeTips);
        //   if(tips > (parseFloat(0.15)*newDetails.totalExpBeforeTips)){
        //     setTimeout(() => {
        //       props.loader({loader:false,notification:true,message:"error",validationMessage:'Tips cannot exceed 15% of the expense.' });
        //     }, 1000);
        //   }
        /*if((newDetails.allowedTips !== null) && (tips > parseFloat(newDetails.allowedTips))){
          reimbAmount = reimbAmount + parseFloat(newDetails.allowedTips);
        }
        else{
          reimbAmount = reimbAmount + tips;
        }*/

        if ((newDetails.maxAllowedAmount !== null) && ((expWoTips + tips) > parseFloat(newDetails.maxAllowedAmount))) {
            //newDetails.rembAmount = parseFloat(newDetails.maxAllowedAmount);
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Maximum allowed amount has been exceeded.' });
        }
        if ((newDetails.maxAllowedAmount !== null) && (expWoTips > parseFloat(newDetails.maxAllowedAmount))) {
            newDetails.rembAmount = reimbAmount + parseFloat(newDetails.maxAllowedAmount);
        }
        else if (newDetails.totalTips < (parseFloat(0.15) * parseFloat(expWoTips))) {
            newDetails.rembAmount = reimbAmount + expWoTips + newtips;
        }
        else {
            newDetails.rembAmount = reimbAmount + expWoTips + newtips; //(parseFloat(0.15) * parseFloat(expWoTips));            
        }

        if (newDetails.rembAmount !== null && newDetails.rembAmount !== '' && (newDetails.rembAmount > parseFloat(newDetails.maxAllowedAmount))) {
            newDetails.rembAmount = parseFloat(newDetails.maxAllowedAmount);
        }
        if ((newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && (parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.rembAmount))) {
            newDetails.dhsCardAmount = null;

        }
        else if (newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '' && (parseFloat(newDetails.dhsCardAmount) <= parseFloat(newDetails.rembAmount))) {
            newDetails.dhsCardAmount = null;
        }
        else {
            newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);
        }
        newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);

        setDefaultRembAmt(newDetails.rembAmount);
        setDefaultDhsCardAmount(newDetails.dhsCardAmount);

        if (newDetails.dhsCardSw === 'P') {
            if(newDetails.rembAmount == 0.0 && newDetails.dhsCardAmount>0){
                newDetails.rembAmount = newDetails.dhsCardAmount
            }

            newDetails.dhsCardAmount = null;
            newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);
        }
        if (newDetails.dhsCardSw === 'D' || newDetails.dhsCardSw === 'C') {
            if ( (newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.rembAmount)) || (newDetails.rembAmount === null))) {
                newDetails.rembAmount = newDetails.dhsCardAmount;
                newDetails.dhsCardAmount = 0.0;
            }
            newDetails.dhsCardAmount = parseFloat(newDetails.totalExpPaid);
            newDetails.rembAmount = parseFloat(0.0).toFixed(2);
        }

        setMealsDetails(newDetails);
    }
    const expenseCal = (newDetails) => {
        let expWoTips = parseFloat(0);
        let tips = parseFloat(0);
        let reimbAmount = parseFloat(0);
        let newtips = parseFloat(0);
        meals.forEach((meal) => {
            expWoTips = parseFloat(expWoTips) + ((meal.expWoTip !== null && meal.expWoTip !== '') ? parseFloat(meal.expWoTip) : 0);
            tips = parseFloat(tips) + ((meal.tips !== null && meal.tips !== '') ? parseFloat(meal.tips) : 0);
            if ((meal.tips !== null && meal.tips !== '')) {
                if (parseFloat(meal.tips) > (parseFloat(0.15) * parseFloat(meal.expWoTip))) {
                    newtips = newtips + (parseFloat(0.15) * parseFloat(meal.expWoTip));
                } else {
                    newtips = newtips + parseFloat(meal.tips);
                }
            }
        });

        newDetails.totalExpBeforeTips = parseFloat(expWoTips).toFixed(2);
        newDetails.totalTips = parseFloat(tips).toFixed(2);
        newDetails.totalExpPaid = parseFloat(expWoTips + tips).toFixed(2);
        newDetails.allowedTips = parseFloat(0.15) * parseFloat(newDetails.totalExpBeforeTips);
        //  if(tips > (parseFloat(0.15)*newDetails.totalExpBeforeTips)){
        //     setTimeout(() => {
        //      props.loader({loader:false,notification:true,message:"error",validationMessage:'Tips cannot exceed 15% of the expense.' });
        //  }, 1000);
        // }
        /*if((newDetails.allowedTips !== null) && (tips > parseFloat(newDetails.allowedTips))){
          reimbAmount = reimbAmount + parseFloat(newDetails.allowedTips);
        }
        else{
          reimbAmount = reimbAmount + tips;
        }*/
        if ((newDetails.maxAllowedAmount !== null) && ((expWoTips + tips) > parseFloat(newDetails.maxAllowedAmount))) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Maximum allowed amount has been exceeded.' });
        }
        if ((newDetails.maxAllowedAmount !== null) && (expWoTips > parseFloat(newDetails.maxAllowedAmount))) {
            newDetails.rembAmount = reimbAmount + parseFloat(newDetails.maxAllowedAmount);
        }
        else if (newDetails.totalTips < (parseFloat(0.15) * parseFloat(expWoTips))) {
            newDetails.rembAmount = reimbAmount + expWoTips + newtips;
        }
        else {
            newDetails.rembAmount = reimbAmount + expWoTips + newtips;
        }

        if (newDetails.rembAmount !== null && newDetails.rembAmount !== '' && (newDetails.rembAmount > parseFloat(newDetails.maxAllowedAmount))) {
            newDetails.rembAmount = parseFloat(newDetails.maxAllowedAmount);
        }

        if ((newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && (parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.rembAmount))) {
            newDetails.dhsCardAmount = null;

        }
        else if (newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '' && (parseFloat(newDetails.dhsCardAmount) <= parseFloat(newDetails.rembAmount))) {
            newDetails.rembAmount = newDetails.rembAmount - parseFloat(newDetails.dhsCardAmount);
        }
        else {
            newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);
        }


        newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);
        return newDetails;
    }
      
    const  checkMealsExpenseDate = async (expenseDate) => {
        if (!expenseDate) {
            // Skip the check if no date is provided
            return;
        }
        let formattedExpenseDate = moment(expenseDate).format('YYYY-MM-DD');
        let details = { expenseDate: formattedExpenseDate, userId: props.userInfo.id };
    
        try {
            const data = await postFetch("/expense/checkMealsExpenseDate", details);
    
            if (data.exists) {
                const reimbursementData = data.reimbursementData || [];
    
                // Filter to only include 'meals' expense types
                const mealsExpenses = reimbursementData.filter(item => item.expenseType === 'meals' && item.delSw === 'N');
    
                if (mealsExpenses.length > 0) {
                    // Extract and ensure unique reimbursement IDs
                    const reimbursementIds = [...new Set(
                        mealsExpenses.map(item => item.reimbursementId).filter(id => id !== undefined && id !== null)
                    )];
                        
                    if (reimbursementIds.length > 0) {
                        const reimbursementIdsString = reimbursementIds.join(", ");
                        const message = `You already have a meals expense for this date in Reimbursement ${reimbursementIdsString}. Please ensure this is not a duplicate expense.`;
    
                         
                        // Set the warning message and ensure it is shown
                        setWarningMessage(message);
                        setShowWarning(true);
                    }
                }
            } else {
                // Clear the warning if no expense exists
                setWarningMessage("");
                setShowWarning(false);
            }
        } catch (e) {
            // Handle system error and show warning
            setWarningMessage("System error. Please try again later.");
            setShowWarning(true);
        }
    }


    const changeMealsDetails = (key, value) => {
        let newDetails = { ...mealsDetails };

        if (key === 'expenseDate') {
            if (value !== null && value !== "") {
                // Update the expense date in the state
                newDetails[key] = value;
                setMealsDetails(newDetails); 
                checkMealsExpenseDate(value);
            } else {
                // Clear warning and reset state when the date is null or empty
                newDetails[key] = value;
                setMealsDetails(newDetails);
                setWarningMessage("");  // Clear warning message
                setShowWarning(false);  // Hide warning
            }
        } else {
            newDetails[key] = value;
            setMealsDetails(newDetails);  // Update other fields
        }
        if(key === 'city' && newDetails.state !== null && props.userInfo.userType === 'EM')
        {
            if((["Arkansas"].indexOf(newDetails.state)  === -1))
            {
                newDetails.outOfState = 'Y';
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "Location selected requires Out of State Travel Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                }, 1000);
            }else{
                newDetails.outOfState = 'N';
            }
        }
        if ((key === 'city' && value !== null && newDetails.expenseDate !== null) || (key === 'expenseDate' && value !== null && newDetails.city !== null && moment(value, 'MM/DD/YYYY').isValid())) {
            let request = {
                expenseStartDate: key === 'expenseDate' ? moment(value).format("YYYY-MM-DD") : moment(newDetails.expenseDate).format("YYYY-MM-DD"),
                internalOrder: newDetails.state,
                costCenter: key === 'expenseDate' ? newDetails.city : value
            }
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            postFetch("/reimbursement/getRates", request).then((data) => {
                if (!data.message) {
                    props.loader({ loader: false, notification: false, message: "success" });
                    key === 'expenseDate' ? newDetails.expenseDate = value : newDetails.expenseDate = newDetails.expenseDate;
                    if ((newDetails.expenseDate.setHours(0, 0, 0, 0) < new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0)) && (newDetails.expenseDate.setHours(0, 0, 0, 0) > new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
                        newDetails.maxAllowedAmount = parseFloat(data.mealRate);
                        //newDetails.allowedTips = parseFloat(0.15) * parseFloat(data.mealRate);
                    }
                    else {
                        newDetails.maxAllowedAmount = parseFloat(0.75) * parseFloat(data.mealRate);
                        //newDetails.allowedTips = parseFloat(0.75) * parseFloat(parseFloat(0.15) * parseFloat(data.mealRate));
                    }
                    newDetails = expenseCal(newDetails);
                    newDetails[key] = value;
                    setMealsDetails(newDetails);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        else {
            if (key === 'state' && value !== null) {
                newDetails.city = null;
                getCities(value);
            }
            if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseStartDate !== null) && (value.setHours(0, 0, 0) < new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
                value = null;
                props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be prior to the Reimbursement Expense Start Date' });
            }
            if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseEndDate !== null) && (value.setHours(0, 0, 0) > new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0))) {
                value = null;
                props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be later than the Reimbursement Expense End Date' });
            }


            if(key === 'dhsCardSw'){
                newDetails.dhsCardAmount = defaultDhsCardAmount;
                newDetails.rembAmount = defaultRembAmt;
                if (value === 'P') {
                    if(newDetails.rembAmount == 0.0 && newDetails.dhsCardAmount>0){
                        newDetails.rembAmount = newDetails.dhsCardAmount
                    }
                    newDetails.dhsCardAmount = null;
                    newDetails.rembAmount = parseFloat(newDetails.rembAmount).toFixed(2);
                }
                if (value === 'D' || value === 'C') {
                    if ((newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.rembAmount)) || (newDetails.rembAmount  === null))) {
                        newDetails.rembAmount = newDetails.dhsCardAmount;
                        newDetails.dhsCardAmount = 0.0;
                    }
                    newDetails.dhsCardAmount = parseFloat(newDetails.totalExpPaid);
                    newDetails.rembAmount = parseFloat(0.0).toFixed(2);

                }

            }
            newDetails[key] = value;
            if((key === "expReason" || key === "expenseDate") && (newDetails.expReason === null || newDetails.expReason === 0 || newDetails.expenseDate === null))
            {
                newDetails.ledgerCode = null;
                newDetails.internalOrder = null;
                newDetails.costCenter = null;
            }

            if(key === 'expenseDate' && value !== null && props.userInfo.userType === 'EM')
            {       
                var expenseDtStateHoliday = stateHoliday.filter((holiday) => holiday.holidayDate === moment(value).format("YYYY-MM-DD") && holiday.active === 'Y').length > 0 ? true : false;
                var dayOfWeek = new Date(value).getDay();
                if((dayOfWeek === 6 || dayOfWeek  === 0) || (expenseDtStateHoliday)){
                    newDetails.outsideWorkHours = 'Y';
                    mealsDetails["outsideWorkHours"] = 'Y';
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                    }, 1000);
                } else{
                    newDetails.outsideWorkHours = 'N';
                    mealsDetails["outsideWorkHours"] = 'N';
                }
                // postFetch("/expense/checkStateHoliday", request).then((data) => {
                //     if (!data.message) {
                //         props.loader({ loader: false, notification: false, message: "success" });
                //         var dayOfWeek = new Date(value).getDay();
                //         if((dayOfWeek === 6 || dayOfWeek  === 0) || (data.holidayCount > 0)){
                //                 newDetails.outsideWorkHours = 'Y';
                //                 setTimeout(() => {
                //                     props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                //                 }, 1000);
                //             } else{
                //                 newDetails.outsideWorkHours = 'N';
                //             }
                //     }
                //     else {
                //         setTimeout(() => {
                //             props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                //         }, 1000);
                //     }
                // }).catch((e) => {
                //     setTimeout(() => {
                //         props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                //     }, 1000);
                // });
            }
            setMealsDetails(newDetails);
            if((key === "expReason" || key === "expenseDate") && (newDetails.expReason !== null && newDetails.expReason !== 0) && (newDetails.expenseDate !== null) && (moment(newDetails.expenseDate, 'MM/DD/YYYY').isValid())){
                let request = {
                    expense: 'ME',
                    expenseType: 'NM',
                    reasonForExpense: newDetails.expReason,
                    userType: props.userInfo.userType,
                    division:  (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? props.reimbursement.division : (props.userInfo.role === 'PR') ? props.userInfo.division : props.travellerInfo.divisionCode,
                    startDate: new Date(newDetails.expenseDate)
                }
                getIOCC(request);
            }
        }

    }

    const getCities = async (value) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadCities", value).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setCities([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStates = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadStates", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStates([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStateHolidays = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/expense/getStateHoliday", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStateHoliday([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getIOCC = async (request) => {
        let newDetails = { ...mealsDetails };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFetch("/financialAdministration/loadIOCC", request).then((data) => {
            if (!data.message) {
                props.loader({ loader: false, notification: false, message: "success" });
                newDetails.internalOrder = data.internalOrder;
                newDetails.costCenter = data.costCenter;
                newDetails.expReason = request.reasonForExpense;
                newDetails.ledgerCode = data.generalLedgerCode;
                newDetails.expenseDate = request.startDate;
                //if(request.userType === 'EM' && request.expenseType !== 'ME')
                if(data.internalOrder === null || data.internalOrder === '')
                {                   
                    newDetails.internalOrder = props.travellerInfo.empInternalOrder;
                    newDetails.costCenter = props.travellerInfo.empCostCenter;
                }
                setMealsDetails(newDetails);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const addNewMealExp = async () => {
        setMealsDetails({});  // Reset mileage details
        setWarningMessage("");  // Clear the warning message
        setShowWarning(false);  // Hide the warning message  
        getStateHolidays();
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...details };
        await postFetch("/reimbursement/loadStates", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStates([...data]);
                    setMealsDetails({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, totalExpBeforeTips: null, totalTips: null, allowedTips: null, totalExpPaid: null, maxAllowedAmount: null, rembAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medApprovalStatus: null, medRevReason: null, delSw: 'N', id: null, internalOrder: null, costCenter: null,outOfState: 'N', outsideWorkHours: 'N' });
                    setError(false);
                    setMeals([{ mealType: "Breakfast", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
                    { mealType: "Lunch", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null },
                    { mealType: "Dinner", expWoTip: null, tips: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, internalOrder: null, costCenter: null, mealsExpId: null }]);
                    setMedicaid('N');
                    setMedicaidSw(false);
                    setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null });
                    setDocuments([]);
                    history.push("/tr1/mealsexp");
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });


    }
    const mealsDetailsColumns = [
        {
            name: "Expense Date",
            field: "sumexpenseDate",
            type: "text"
        },
        {
            name: "Reason for Expense",
            field: "sumexpenseReason",
            type: "text"
        },
        {
            name: "Amount",
            field: "sumamount",
            type: "text"
        },
        {
            name: "Status",
            field: "sumstatus",
            type: "text"
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: summaryActionButton
        }
    ]
    const mealsColumns = [
        {
            name: "Meal",
            field: "mealType",
            type: "text"
        },
        {
            name: "Expense w/o Tips",
            field: "expWoTip",
            type: "input",
            renderView: woTips
        },
        {
            name: "Tips Paid",
            field: "tips",
            type: "input",
            renderView: tips
        }
    ]

    const uploadColumns = [
        {
            name: "File Name",
            field: "fileName",
            type: "text"
        },
        {
            name: "Size",
            field: "size",
            type: "text"
        },
        {
            name: "Medicaid?",
            field: "medSw",
            type: "input",
            renderView: documentMedicaid
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: documentActionButton
        }
    ]


    return (
        <div>
            <Switch>
                <Route exact path="/tr1">
                    <div className={classes.root}>
                        <Typography className={classes.header}>Meals Expense Summary</Typography>
                        <Box style={{ float: 'right' }} m={2}>
                            <Button variant="outlined" color="primary" size="small" className={(readOnly || (props.pageInfo.find((page) => page.pageId === 1).permissionSw !== 'Y')) ? "disabled" : null} disabled={(readOnly || (props.pageInfo.find((page) => page.pageId === 1).permissionSw !== 'Y'))} onClick={() => addNewMealExp()}>Add New</Button>
                        </Box>
                        <Paper elevation={0}>
                            <Box p={"10px"} pb={"60px"}>
                                <Gridtable columns={mealsDetailsColumns} data={summaryData}></Gridtable>
                                <div className="summaryTotal">
                                    <span>Total Amount: ${totalAmount}</span>
                                </div>
                            </Box>
                        </Paper>
                    </div>
                </Route>
                <Route exact path="/tr1/mealsexp">
                    <div>
                        <Typography className={classes.header}>Meals Expense Details</Typography>

                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.datepicker}>
                                <span className="datepickerLabel">Expense Date</span>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        //disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="expense-date"
                                        autoOk={true}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        size="small"
                                        fullWidth
                                        value={mealsDetails.expenseDate}
                                        error={(mealsDetails.expenseDate === null) || (!moment(mealsDetails.expenseDate, 'MM/DD/YYYY').isValid())}
                                        minDate={props.reimbursement.expenseStartDate !== null ? props.reimbursement.expenseStartDate : null}
                                        maxDate={props.reimbursement.expenseEndDate !== null ? props.reimbursement.expenseEndDate : new Date()}
                                        onChange={(date) => changeMealsDetails('expenseDate', date)}
                                        placeholder="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'expense date',
                                        }}
                                        inputVariant="outlined"
                                        helperText={(mealsDetails.expenseDate === null && error) ? "Required Field" : null}
                                    />
                                </MuiPickersUtilsProvider>
                               {/* Warning Message Display */}
                               {showWarning && warningMessage && (
            <FormHelperText style={{ color: 'red', marginTop: '10px' }}>
                {warningMessage}
            </FormHelperText>)}
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={mealsDetails.expReason === 0 || mealsDetails.expReason === null}>
                                    <span className={classes.textLabel}>Reason for Expense</span>
                                    <Select
                                        id="reason-select"
                                        value={mealsDetails.expReason === null ? 0 : mealsDetails.expReason}
                                        onChange={(e) => changeMealsDetails('expReason', e.target.value)}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                                            <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {((mealsDetails.expReason === 0 || mealsDetails.expReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Subheader>For Agency Payment Purposes Only</Subheader>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>General Ledger Code </span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={(mealsDetails.ledgerCode === null || mealsDetails.ledgerCode === 0) ? '' : mealsDetails.ledgerCode}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Internal Order</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expinternalOrder"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={mealsDetails.internalOrder === null ? '' : mealsDetails.internalOrder}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Cost Center</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expcostCenter"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={mealsDetails.costCenter === null ? '' : mealsDetails.costCenter}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={mealsDetails.state === 0 || mealsDetails.state === null}>
                                    <span className={classes.textLabel}>State</span>
                                    <Select
                                        id="state-select"
                                        value={mealsDetails.state === null ? 0 : mealsDetails.state}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeMealsDetails('state', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {states.sort().map((method) => (
                                            <MenuItem key={method} value={method}>{method}</MenuItem>
                                        ))}
                                    </Select>
                                    {((mealsDetails.state === 0 || mealsDetails.state === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={mealsDetails.city === 0 || mealsDetails.city === null}>
                                    <span className={classes.textLabel}>City</span>
                                    <Select
                                        id="city-select"
                                        value={mealsDetails.city === null ? 0 : mealsDetails.city}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeMealsDetails('city', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {(mealsDetails.state === 0 || mealsDetails.state === null) ? null : cities.map((value) => (
                                            <MenuItem key={value} value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                    {((mealsDetails.city === 0 || mealsDetails.city === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={12}>
                                <Box pr={"10px"}>
                                    <Gridtable columns={mealsColumns} data={meals}></Gridtable>
                                </Box>
                            </Grid>
                        </Grid>
                        {medicaid === 'Y' ? <div>
                            <Subheader>Medicaid Details</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>First Name</span>
                                    <TextField
                                        id="firstName"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medFirstname === null ? '' : currentMedicaid.medFirstname}
                                        error={currentMedicaid.medFirstname === '' || currentMedicaid.medFirstname === null}
                                        onChange={(e) => changeMedicaidDetails("medFirstname", e.target.value)}
                                        helperText={((currentMedicaid.medFirstname === '' || currentMedicaid.medFirstname === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Last Name</span>
                                    <TextField
                                        id="lastName"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medLastname === null ? '' : currentMedicaid.medLastname}
                                        error={currentMedicaid.medLastname === '' || currentMedicaid.medLastname === null}
                                        onChange={(e) => changeMedicaidDetails("medLastname", e.target.value)}
                                        helperText={((currentMedicaid.medLastname === '' || currentMedicaid.medLastname === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.datepicker}>
                                    <span className="datepickerLabel">Date of Birth</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            //disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="dob-date"
                                            autoOk={true}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            size="small"
                                            fullWidth
                                            value={currentMedicaid.medDob}
                                            error={(currentMedicaid.medDob === null) || (!moment(currentMedicaid.medDob, 'MM/DD/YYYY').isValid())}
                                            onChange={(date) => changeMedicaidDetails('medDob', date)}
                                            maxDate={new Date()}
                                            placeholder="MM/DD/YYYY"
                                            KeyboardButtonProps={{
                                                'aria-label': 'dob date',
                                            }}
                                            inputVariant="outlined"
                                            helperText={((currentMedicaid.medDob === null && error)) ? "Required Field" : null}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {(currentMedicaid.medDob !== null && !moment(currentMedicaid.medDob, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                                        : null}
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Medicaid Number</span>
                                    <TextField
                                        id="medNum"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medId === null ? '' : currentMedicaid.medId}
                                        error={currentMedicaid.medId === '' || currentMedicaid.medId === null}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        onChange={(e) => changeMedicaidDetails("medId", e.target.value)}
                                        helperText={((currentMedicaid.medId === '' || currentMedicaid.medId === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Internal Order</span>
                                    <TextField
                                        id="internalOrder"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className="disabled"
                                        value={props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'IO').name}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Cost Center</span>
                                    <TextField
                                        id="costCenter"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className="disabled"
                                        value={props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'CO').name}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                                <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" className={readOnly ? "disabled" : null} disabled={readOnly} onClick={() => saveMeal(currentMedicaid.index)}>Verify</Button>
                            </div>
                        </div> : null}
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Total Expenses Before Tips</span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.totalExpBeforeTips === null ? 0 : parseFloat(mealsDetails.totalExpBeforeTips).toFixed(2))}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Maximum Allowed Amount</span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.maxAllowedAmount === null ? 0 : parseFloat(mealsDetails.maxAllowedAmount).toFixed(2))}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Total Tips Paid</span>
                                <TextField
                                    id="rate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.totalTips === null ? 0 : parseFloat(mealsDetails.totalTips).toFixed(2))}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Allowed Tips</span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.allowedTips === null ? 0 : parseFloat(mealsDetails.allowedTips).toFixed(2))}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Total Expenses Paid</span>
                                <TextField
                                    id="rate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.totalExpPaid === null ? 0 : mealsDetails.totalExpPaid)}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Reimbursement Amount</span>
                                <TextField
                                    id="rate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (mealsDetails.rembAmount === null ? 0 : mealsDetails.rembAmount)}
                                />
                            </Grid>
                        </Grid>
                        {props.userInfo.role !== 'PR' ? <Grid container className={classes.filters}>
                            <Grid item xs={12} className={classes.textfield}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="stateCard-sw" name="stateCard-sw" defaultValue={(mealsDetails.dhsCardSw == 'D' || mealsDetails.dhsCardSw == 'C') ? mealsDetails.dhsCardSw : 'P'} onChange={(e) => changeMealsDetails('dhsCardSw', e.target.value)}>
                                        <FormLabel component="legend" className={classes.radioLabel}>How have you paid for this expense?</FormLabel>
                                        <div>
                                            <FormControlLabel value="P" control={<Radio disabled={readOnly} color="primary" />} label="Personal" />
                                            <FormControlLabel value="D" control={<Radio disabled={readOnly} color="primary" />} label="Direct Billing" />
                                            <FormControlLabel value="C" control={<Radio disabled={readOnly} color="primary" />} label="DHS Credit Card" />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid> : null}
                        {mealsDetails.dhsCardSw === 'D' || mealsDetails.dhsCardSw === 'C' ? <div>
                            <Grid container className={classes.filters}>
                                <Grid item xs={12} sm={6} className={classes.textfield}>
                                    <span className={classes.textLabel}>Direct Billing/DHS Credit Card Amount</span>
                                    <TextField
                                        id="stateCardAmnt"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={"disabled"}
                                        disabled={true}
                                        value={mealsDetails.dhsCardAmount === null ? '' : mealsDetails.dhsCardAmount}
                                        error={mealsDetails.dhsCardAmount === '' || mealsDetails.dhsCardAmount === null}

                                        InputProps={{
                                            inputComponent: AmountFormatCustom,
                                            readOnly:true
                                        }}
                                        helperText={((mealsDetails.dhsCardAmount === '' || mealsDetails.dhsCardAmount === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                        </div> : null}

                        <Subheader>Upload Documents</Subheader>
                        <Grid container style={{ padding: "0 10px" }}>
                            <Grid item xs={12}>
                                <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                    <Button variant="outlined" color="primary" size="small" className={readOnly ? "disabled" : null}
                                        disabled={readOnly}>
                                        <span style={{ position: 'absolute', maxWidth: '210px'  }}>Select FIle & Upload</span>
                                        <input
                                            color="primary"
                                            type="file"
                                            onChange={(event) => upload(event.target)}
                                            id="upload-file"
                                            style={{ opacity: 0, maxWidth: '210px', cursor: 'pointer' }}
                                        />
                                    </Button>
                                </Box>
                                <Box pr={"10px"}>
                                    <Gridtable columns={uploadColumns} data={documents.filter((doc) => doc.delSw !== 'Y')}></Gridtable>
                                </Box>
                            </Grid>
                        </Grid>

                        {((mealsDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) ? <div>
                            <Subheader>Travel Supervisor Status Details</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mealsDetails.status === 'AP'}
                                                onChange={() => changeMealsDetails('status', 'AP')}
                                                name="Approve"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                            />
                                        }
                                        label="Approve"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mealsDetails.status === 'RR'}
                                                onChange={() => changeMealsDetails('status', 'RR')}
                                                name="Return for Revision"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || returnRevisionSw)}
                                            />
                                        }
                                        label="Return for Revision"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            {mealsDetails.status === 'RR' ? <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={mealsDetails.revReason === 0 || mealsDetails.revReason === null}>
                                        <span className={classes.textLabel}>Reason for Revision</span>
                                        <Select
                                            id="reason-select"
                                            value={mealsDetails.revReason === null ? 0 : mealsDetails.revReason}
                                            className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                            disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                            onChange={(e) => changeMealsDetails('revReason', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {props.staticData.dropdown.ReasonForRevision.value.map((method) => (
                                                <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {((mealsDetails.revReason === 0 || mealsDetails.revReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                            </Grid> : null}
                        </div> : null}
                        {((mealsDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id) && medicaidSw) ? <div>
                            <Subheader>Medicaid Status Details</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mealsDetails.medApprovalStatus === 'AP'}
                                                onChange={() => changeMealsDetails('medApprovalStatus', 'AP')}
                                                name="Approve"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                            />
                                        }
                                        label="Approve"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mealsDetails.medApprovalStatus === 'RR'}
                                                onChange={() => changeMealsDetails('medApprovalStatus', 'RR')}
                                                name="Return for Revision"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                            />
                                        }
                                        label="Return for Revision"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            {mealsDetails.medApprovalStatus === 'RR' ? <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={mealsDetails.medRevReason === 0 || mealsDetails.medRevReason === null}>
                                        <span className={classes.textLabel}>Reason for Revision</span>
                                        <Select
                                            id="reason-select"
                                            value={mealsDetails.medRevReason === null ? 0 : mealsDetails.medRevReason}
                                            className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                            disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                            onChange={(e) => changeMealsDetails('medRevReason', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {props.staticData.dropdown.ReasonForRevision.value.map((method) => (
                                                <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {((mealsDetails.medRevReason === 0 || mealsDetails.medRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                            </Grid> : null}
                        </div> : null}


                        <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                        <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={() => reset()}>Reset</Button>
                        <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={() => saveMeals()}>Save</Button>
                        </div>
                    </div>
                </Route>
            </Switch>
        </div>
    )

}